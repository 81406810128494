<template>
  <div class="Content-Page">
    <div class="N-Page T-size-36">Manage Company</div>

    <div class="B-btn">
      <v-btn class="theme-btn-even" @click.stop="addItem()">
        <v-icon>mdi-plus</v-icon>Add
      </v-btn>
    </div>

<p>{{date | yyyy-MM-dd}}</p>
        <p>{{date | HHmm}}</p>
        <p>{{date | HHmmss}}</p>

    <v-text-field v-model="search" append-icon="mdi-account-search" label="Search" outlined></v-text-field>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      @current-items="getFiltered"
      id="printMe"
    >
      <template v-slot:item.action="{ item }" class="text-xs-right">
        <v-icon @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
      </template>
    </v-data-table>
    <button @click="print()">print Me !</button>
    <downloadexcel
      class="btn btn-default"
      :data="json_data"
      :fields="json_fields"
      worksheet="My Worksheet"
      name="filename.xls"
    >Download Excel (you can customize this with html code!)</downloadexcel>
    <div style="text-align:center;">
      <h1>Export JSON to Excel</h1>
      <button @click="onExport">Export</button>
      <!-- เพิ่มปุ่ม Export -->
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import downloadexcel from "vue-json-excel";
import XLSX from "xlsx";
import "../plugins/htmltopdf";
import '../utils/filterdate.js'
 
export default {
  data: () => ({
          date: new Date("2020-04-05 13:25:00"),

    json_fields: {
      "Company Code": "Code",
      "Company Name": "Name",
      "Alcohol Limit": "AlcoholLimit",
      "Temperature Limit": "TemperatureLimit"
    },
    json_data: [],
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8"
        }
      ]
    ],

    search: "",
    datafilter: null,
    headers: [
      { value: "Code", text: "Company Code", sortable: true },
      { value: "Name", text: "Company Name", sortable: true },
      { value: "AlcoholLimit", text: "Alcohol Limit (mg.)", sortable: true },
      {
        value: "TemperatureLimit",
        text: "Temperature Limit (°C)",
        sortable: true
      },
      { value: "action", text: "", sortable: false }
    ],
    defaultValue: {
      Name: ""
    },
    query: { sort: { Id: -1 } },
    //--end config

    items: [], // data ที่มาจากการ find ของ server
    loading: false,
    output: null
  }),
  computed: {},
  components: {
    downloadexcel
  },
  async mounted() {
    this.renderUI();
  },
  methods: {
    async renderUI() {
      try {
        var res = await feathersClientUOA.service("company").find(this.query);
        this.items = res.data;
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    getFiltered(e) {
      this.json_data = e;
      console.log(e);
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.json_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    print() {
      //this.$htmlToPaper('printMe');
      this.$htmlToPaper("printMe", null, () => {
        console.log("Printing completed or was cancelled!");
      });
    }
  }
};
</script>