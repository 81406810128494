import Vue from 'vue';
import VueHtmlToPaper from 'vue-html-to-paper';
 
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    // 'http://203.151.121.245:9062/css/resetcss.css',
    // 'http://203.151.121.245:9062/css/animate.min.css',
    // 'http://203.151.121.245:9062/css/theme.css',
    // 'http://203.151.121.245:9062/css/main-style.css',
    // 'http://203.151.121.245:9062/css/page-style.css'

    '../css/resetcss.css',
    '../css/animate.min.css',
    '../css/theme.css',
    '../css/main-style.css',
    '../css/page-style.css'
    
  ]
}
 
Vue.use(VueHtmlToPaper, options);
 
// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);